import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import MainLayout from "src/layout";
import Helmet from "react-helmet";

const AboutPage = ({ data }) => {
  const domHeadshot = getImage(data.domHeadshot);
  const tobyHeadshot = getImage(data.tobyHeadshot);
  return (
    <main>
      <Helmet title={`About | The Wolf is Dead`} />
      <MainLayout>
        <section className="section">
          <div className="container">
            <h2 className="p-b-m">About</h2>
            <div className="columns">
              <div className="column is-narrow">
                <GatsbyImage image={tobyHeadshot} className="m-r-m m-b-m" />
                <GatsbyImage image={domHeadshot} />
              </div>
              <div className="column">
                <p>
                  <span>Toby:</span> Wolf is Dead started as a lockdown project,
                  and here we are! Making it has been wearing as many hats as
                  possible and a huge learning curve.
                </p>
                <p>
                  Dom: Toby is the primary lead. He's done all hard work: the
                  coding, the modelling, the posing. I've done all the marketing
                  and then we've both worked on the script.
                </p>
                <p>
                  Toby: We've got help for the art &amp; visual design. We're
                  working with two wonderful artists. The character design we
                  did together with{" "}
                  <a href="https://www.artstation.com/leonardo_ambrosini">
                    Leonardo Ambrosini
                  </a>{" "}
                  and the house interior design we did in tandem with{" "}
                  <a href="https://isabella-bicego.wixsite.com/portfolio">
                    Isabella Bicego
                  </a>
                  .
                </p>
                <p>Dom: Yes we are brothers. No we&apos;re not twins.</p>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </main>
  );
};

export default AboutPage;

export const aboutQuery = graphql`
  query IndexQuery2 {
    tobyHeadshot: file(relativePath: { eq: "toby_headshot_txt.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, aspectRatio: 1)
      }
    }
    domHeadshot: file(relativePath: { eq: "dom_headshot_txt.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, aspectRatio: 1)
      }
    }
  }
`;
